.container {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.instruction {
    font-size: 16px;
    margin-bottom: 10px;
}

.code-block {
    background-color: #2e2e2e;
    border-radius: 8px;
    padding: 16px;
    color: #ffffff;
    font-family: "Courier New", Courier, monospace;
    position: relative;
}

.code-header {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.red {
    background-color: #ff5f57;
}

.yellow {
    background-color: #ffbd2e;
}

.green {
    background-color: #28c840;
}

pre {
    margin: 0;
}

code {
    font-size: 14px;
}


.code-content {
    max-height: 400px; /* Limit code height */
    overflow-x: auto; /* Enable horizontal scroll */
    overflow-y: auto; /* Enable vertical scroll if content exceeds max height */
    scrollbar-color: #666 #1e1e1e; /* For Firefox - custom scrollbar colors */
    position: relative !important;
    scrollbar-width: thin !important; /* For Firefox - makes the scrollbar thin */

  }

  
.code-content::-webkit-scrollbar {
    height: 2px !important; /* Height of the horizontal scrollbar */
    width: 2px !important; /* Width of the vertical scrollbar */
  }
  
  .code-content::-webkit-scrollbar-thumb {
    background-color: #666; /* Color of the scrollbar thumb */
    border-radius: 4px !important; /* Rounded edges for the scrollbar thumb */
  }
  
  .code-content::-webkit-scrollbar-track {
    background-color: #1e1e1e; /* Background color of the scrollbar track */
  }